@import url('https://fonts.googleapis.com/css2?family=Abel&display=swap');
:root {
  --color-black: black;
  --header-font-size: 7rem;
  --primary-blue: #c5f4ef;
  --primary-green: #c9f7b0;
}

html {
  scroll-behavior: smooth;
  overflow-x: hidden;
  max-width: 100%;
  font-size: 62.5%;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

.App {
  position: relative;
}

body {
  font-family: 'Abel', sans-serif;
  box-sizing: border-box;
  background: #fafafa;
  color: #333;
  overflow-x: hidden;
}

a {
  text-decoration: none;
  color: #333;
}

.get-in-touch {
  border: none;
  font-family: 'Abel', sans-serif;
  font-weight: 800;
  border-radius: 3px;
  background: linear-gradient(
    to right bottom,
    var(--primary-blue),
    var(--primary-green)
  );
  margin: 9rem 0;
  padding: 2rem 8rem;
  font-size: 2.2rem;
  cursor: pointer;
  transition: all 0.4s;
}
.get-in-touch:hover {
  transform: translateY(-0.5rem);
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.2);
  opacity: 0.9;
}
.icon {
  cursor: pointer;
}
.icon:hover {
  color: lightblue;
}
.header-container {
  position: absolute;
  width: 100%;
  height: 90vh;
  z-index: -1;
  margin: 0;
  background: #c5f4ef;
  clip-path: polygon(100% 0, 10% 0, 100% 100%);
}

section {
  z-index: 2;
  margin: 0 10%;
}

.projects {
  opacity: 0;
  transform: translateY(5rem);
  transition: all 1s ease-in-out;
}

.projects.is-visible {
  opacity: 1;
  transform: translateY(0);
}

.other-project-wrapper {
  position: relative;
}

.other-project-background {
  position: absolute;
  top: 30%;
  z-index: -1;
  margin: 0;
  height: 90vh;
  width: 100%;
  background: #c5f4ef;
  clip-path: polygon(50% 100%, 0 0, 99% 0);
  transform: scale(1.4);
}

.footer-container {
  position: relative;
}

.about-images__img:hover {
  z-index: 2;
  transform: scale(1.18);
  box-shadow: 2px 10px 12px -5px #333;
}
.about-images:hover .about-images__img:not(:hover) {
  transform: scale(0.93);
}

@media (max-width: 1340px) {
  section {
    margin: 0 5%;
  }
}
